@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.container {
    width: 88%;
    margin: 0 auto;
}

.black {
    color: #313131;
}

.blue {
    color: #367BC3;
}

.app {
    min-height: 100vh;
    padding-bottom: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #EF94D1 0%, #A09CDF 100%);
}

/* header */
.header {
    width: 100%;
    padding: 28px 0;
    display: flex;
    justify-content: space-between;
}

.header .logo {
    display: flex;
    gap: 15px;
    width: 250px;
    height: 48px;
}

.header .logo img {
    width: 100%;
    height: 100%;
}

.connect-btn,
.yellow-btn {
    font-size: 18px;
    font-weight: 600;
    color: #313131;
    padding: 12px 30px;
    background: linear-gradient(112.65deg, #FFB400 0%, #FF9C00 100%);
    border-radius: 56px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border: none;
}

/* header end */

/* main page */

.main-page {
    background: linear-gradient(90deg, rgb(255, 211, 238) 0.01%, #A4BDF2 100%);
    box-shadow: 0px 4px 50px 0px #0000000F;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    position: relative;
}

.main-page_text {
    padding-left: 100px;
    width: 49%;
}

.main-page_text h2 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.main-page_text h1 {
    font-size: 56px;
    font-weight: 800;
    margin: 30px 0;
}

.main-page_text p {
    font-size: 14px;
    font-weight: 400;
}

.main-page_text a {
    display: inline-block;
    margin-top: 60px;
    font-weight: 800;
    font-size: 18px;
    padding: 8px 15px;
    background: #FFFFFF;
    border-radius: 64px;
    border: none;
}

.main-page_img {
    width: 49%;
    height: 100%;
}

.main-page_img img {
    width: 100%;
    height: 100%;
    border-radius: 0 32px 32px 0;
    object-fit: cover;
}

/* main page end  */

/*  manage page */
.manage-contract_address-title,
.manage-contract_id-title,
.manage-contract_startDate-title,
.manage-contract_endDate-title {
    display: none !important;
}

.manage {
    background: linear-gradient(90deg, rgb(255, 211, 238) 0.01%, #A4BDF2 100%);
    box-shadow: 0px 4px 50px 0px #0000000F;
    border-radius: 32px;
    height: 80%;
    position: relative;
}

.manage-head {
    background-color: #ecdce9;
    box-shadow: 0px 4px 50px 0px #0000000F;
    padding: 20px 80px;
    border-radius: 32px 32px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manage-heading {
    font-size: 24px;
}

.manage-add {
    padding: 12px 30px;
    border-radius: 56px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #ffaf00;
}

.manage-body {
    width: 100%;
    padding: 20px 50px;
}

.manage-body {
    width: 100%;
    padding: 50px 80px;
}

.manage-body_head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding: 25px 0;
}

.manage-body_head li:first-child {
    width: 35%;
}

.manage-body_head li {
    width: 22.5%;
}

.manage-body_head li span {
    font-size: 16px;
    display: inline-block;
    width: 100%;
}

.manage-body_body-component {
    padding: 20px 0;
    border-bottom: 1px solid #0000005b;
}

.manage-body_content {
    display: flex;
    justify-content: space-between;
}

.manage-body_content li:first-child {
    width: 35%;
}

.manage-body_content li {
    width: 22.5%;
}

.manage-body_content li span {
    font-size: 15px;
    display: inline-block;
    width: 100%;
}

.manage-body_content .manage-actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.manage-body_content .manage-actions .manage-edit_btn {
    cursor: pointer;
    width: 20px;
    height: 20px;
}


/* manage page end  */

/* add contract page */

.add-contract {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px 0px #0000000F;
    border-radius: 32px;
    min-height: 70vh;
    position: relative;
}

.add-contract_head {
    background-color: #ecdce9;
    box-shadow: 0px 4px 50px 0px #0000000F;
    padding: 20px 70px;
    border-radius: 32px 32px 0 0;
    width: 100%;
}

.add-contract_head .link-back {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-contract_head .heading {
    font-size: 18px;
}

.add-contract_body {
    padding: 20px 50px;
}

.add-contract_inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.add-contract_input {
    margin-top: 35px;
    width: 255px;
    height: 56px;
    position: relative;
}

.add-contract_input input {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #FAFAF9, #FAFAF9),
        linear-gradient(0deg, #D0C1D0, #D0C1D0);
    border: 1px solid #D0C1D0;
    border-radius: 12px;
    outline: none;
    padding: 20px 15px 0 15px;
}

.add-contract_input label {
    position: absolute;
    left: 15px;
    top: 5px;
    font-size: 12px;
    color: #7F7667;
}

/* add contract page end */

/* edit contract page  */
.manage-contract {
    margin-top: 50px;
    display: flex;
    gap: 84px;
}

.manage-sidebar ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.manage-sidebar ul li {
    cursor: pointer;
    display: flex;
    gap: 9px;
    padding: 25px 38px;
    width: 231px;
    border-radius: 10px;
    background: #fff;
    border: 2px solid #FFFFFF;
    transition: .4s;
}

.manage-sidebar ul li.active {
    background: #cdabca;
    border: 2px solid #fff;
}

.manage-sidebar ul li:hover {
    background: #cdabca;
}

.manage-sidebar ul li span {
    font-size: 15px;
    font-weight: 400;
}

.manage-contract-content {
    width: 80%;
}

.manage-contract_component {
    width: 100%;
    padding: 58px 80px 33px 73px;
    border-radius: 10px;
    background: #ECDCE9;
}

.manage-contract_component .manage-contract_heading {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
}

.setup-claim-input {
    display: flex;
    flex-direction: column;
    margin: 11px 0;
}

.setup-claim-input label {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 3px;
}

.setup-claim-input input {
    border-radius: 10px;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 400;
    color: #817A7A;
    outline: none;
    border: none;
    height: 37px;
}

.manage-submit_change {
    display: flex;
    justify-content: center;
}

.submit-editContract,
.submit-addContract {
    margin-top: 35px;
    width: 255px;
}

.manage-contract_component h3 {
    margin-top: 34px;
    font-size: 15px;
    font-weight: 400;
}

.manage-wallet_form {
    margin-top: 20px;
}

.manage-wallet_form .manage-wallet_form-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    gap: 33px;
}

.add-wallet_tabs {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ECDCE9;
    border-radius: 10px;
    padding: 6px 18px;
}

.add-wallet_tabs .add-tab {
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 250px;
    transition: .4s;
}

.add-wallet_tabs .add-tab.unactive {
    background-color: #fff;
}

.add-wallet_tabs .add-tab.unactive:hover {
    background-color: #ECDCE9;
}

.custom-file-input {
    display: flex;
    gap: 100px;
    position: relative;
}

.custom-file-input input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
}


.manage-button {
    cursor: pointer;
    background: #ECDCE9;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 400;
}

.add-wallet_by-file .manage-button {
    width: 150px;
    padding: 5px 35px;
}

.custom-file-input input[type="text"] {
    width: 450px;
    padding: 5px 30px;
    font-size: 13px;
    border: 1px dashed #ED77D7;
    color: #999999;
    border-radius: 10px;
    outline: none;
}

.add-wallet_manually {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.add-wallet_manually label {
    font-size: 15px;
    font-weight: 400;
}

.add-wallet_manually textarea {
    width: 100%;
    min-height: 134px;
    border: 1px solid #ECDCE9;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #797979;
    outline: none;
    padding: 14px 23px;
}

/* edit contract page  */

/* connect page */

.connected-page {
    background: rgb(255, 207, 236);
    box-shadow: 0px 4px 50px 0px #0000000F;
    border-radius: 32px;
    min-height: 80vh;
    padding: 70px;
    position: relative;
}

.connected-page h2 {
    font-weight: 900;
    font-size: 32px;
    z-index: 2;
    position: relative;
}

.connected-page .connected-page_release {
    width: max-content;
    background-color: #313131;
    padding: 6px 20px;
    margin: 30px 0;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
}

.release-title {
    font-size: 15px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 5px;
}

.connected-page_release p {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.connected-page_release p span {
    font-weight: 600;
}

.connected-page_content {
    display: flex;
    gap: 100px;
}

.connected-page_content .content-data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    row-gap: 20px;
    z-index: 2;
}

.connected-page_content .content-data .content-data_card {
    width: 48%;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
}

.connected-page_content .content-data .content-data_card h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
}

.connected-page_content .content-data .content-data_card .data-counts {
    margin-top: 12px;
    font-size: 30px;
    font-weight: 600;
    color: #000;
}

.claim-btn {
    z-index: 2;
    cursor: pointer;
}

.connect-left_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
}

.connect-right_bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    height: 100%;
}

.connect-left_bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px 0 0 32px;
}

.connect-right_bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 32px 32px 0;
}

/* connected page end */

/* alert start */
.alert {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0000008c;
    z-index: 100;
    backdrop-filter: blur(7px);
    visibility: hidden;
    opacity: 0;
}

.alert.active {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
    border-radius: 32px;
    opacity: 0;
    transform: translateY(50px);
    transition: .4s;
}

.alert-content.active {
    opacity: 1;
    transform: translateY(0);
}

.alert-content h2 {
    font-size: 56px;
    font-weight: 900;
}

.alert-content img {
    width: 240px;
    height: 240px;
}

.alert-content p,
.alert-content a {
    font-size: 22px;
    font-weight: 500;
    margin: 20px 0;
}

.alert-content span {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 35px;
    font-size: 18px;
    font-weight: 600;
    background-color: #FFFFFF;
    border-radius: 56px;
    cursor: pointer;
}

/* alert end */
/* loading start */
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0000008f;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.loading-content {
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading.active {
    display: flex;
}

.loading img {
    width: 90px;
    height: 90px;
}

@media screen and (max-width: 768px) {
    .app {
        height: auto;
        padding-bottom: 40px;
    }

    .container {
        width: 98%;
    }

    /* header start */
    .header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header .logo {
        width: 232px;
        height: 48px;
    }

    .header .connect-btn {
        margin-top: 20px;
    }

    /* header end */


    /* main page start */
    .main-page {
        flex-direction: column;
        height: auto;
    }

    .main-page_text {
        width: 100%;
        padding-left: 20px;
    }

    .main-page_text h2 {
        margin-top: 50px;
        font-size: 15px;
    }

    .main-page_text h1 {
        font-size: 40px;
        margin: 20px 0;
    }

    .main-page_text p {
        font-size: 13px;
    }

    .main-page_text button {
        font-size: 18px;
        padding: 12px;
        margin-top: 30px;
    }

    .main-page_img {
        width: 100%;
        height: 49%;
    }

    .main-page_img img {
        border-radius: 0 0 32px 32px;
    }

    /* main page end */

    /* connected page start */
    .connected-page {
        height: auto;
        background: linear-gradient(180deg, #FFCFEC 0%, #C1BDEC 100%);
        box-shadow: 0px 4px 50px 0px #0000000F;
        padding: 40px;
    }

    .connected-page h2 {
        font-size: 20px;
    }

    .connected-page .connected-page_release {
        width: 100%;
        gap: 15px;
        flex-wrap: wrap;
    }

    .connected-page_release p {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }

    .connected-page_content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .connected-page_content .content-data {
        width: 100%;
    }

    .connected-page_content .content-data .content-data_card {
        width: 100%;
    }

    .connected-page_content .content-data .content-data_card h4 {
        font-size: 15px;
    }

    .connected-page_content .content-data .content-data_card .data-counts {
        font-size: 22px;
    }

    .claim-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .claim-btn img {
        width: 98px;
        height: 98px;
    }

    .connect-left_bg {
        display: none;
    }

    .connect-right_bg {
        display: none;
    }

    /* connected page end */

    /*  manage page */

    .manage {
        background: none;
        box-shadow: none;
        border-radius: 0;
    }

    .manage-head {
        background: none;
        box-shadow: none;
        padding: 10px 0;
        border-radius: 0;
        justify-content: center;
    }

    .manage-add {
        background-color: #ECDCE9;
    }

    .manage-heading {
        display: none;
    }

    .manage-contract_address-title,
    .manage-contract_id-title,
    .manage-contract_startDate-title,
    .manage-contract_endDate-title {
        display: inline !important;
        color: #797979;
        font-size: 14px !important;
    }

    .manage-body {
        width: 100%;
        padding: 20px 0;
    }

    .manage-body_head {
        display: none;
    }

    .manage-body_body-component {
        margin: 20px 0;
        border-radius: 32px;
        background: #FFFFFF;
        padding: 40px 24px;
        border-bottom: none;
    }

    .manage-body_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    .manage-body_content .manage-contract_address {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        gap: 15px;
    }

    .manage-body_content .manage-contract_address span:last-child {
        text-align: start;
    }

    .manage-body_content li {
        margin: 15px 0;
        width: 100%;
        display: flex;
    }

    .manage-body_content li span:nth-child(2) {
        display: inline-block;
        text-align: end;
    }

    .manage-body_content li span {
        font-size: 13px;
        display: inline-block;
        width: 100%;
        font-weight: 700;
    }

    .manage-body_content .manage-actions .manage-edit_btn {
        position: absolute;
        right: 0;
        top: 15px;
    }

    /*  manage page end */

    /* add contract page */
    /* add contract page */

    .add-contract {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
    }

    .add-contract_head {
        padding: 20px 50px 0 50px;
        box-shadow: none;
        background: none;
    }

    .add-contract_head .heading {
        font-size: 15px;
    }

    .add-contract_body {
        padding: 0px 50px 20px 50px;
    }

    .add-contract_inputs {
        flex-direction: column;
        margin-top: 24px;
    }

    .add-contract_input {
        margin-top: 0;
        width: 100%;
    }

    .submit-addContract {
        width: 100%;
    }

    /* add contract page end */

    /* edit contract page  */
    .manage-contract {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .manage-sidebar {
        width: 100%;
    }

    .manage-sidebar ul {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
    }

    .manage-sidebar ul li {
        display: flex;
        justify-content: center;
        gap: 9px;
        border-radius: 56px;
        padding: 8px 0px;
        width: 152px;
    }

    .manage-sidebar ul li img {
        display: none;
    }

    .manage-sidebar ul li span {
        font-size: 18px;
    }

    .manage-contract-content {
        width: 100%;
    }

    .manage-contract_component {
        width: 100%;
        padding: 40px 24px;
        border-radius: 32px;
    }


    .add-wallet_tabs {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ECDCE9;
        border-radius: 10px;
        padding: 6px 18px;
    }

    .add-wallet_tabs .add-tab {
        width: 49%;
    }

    .custom-file-input {
        flex-direction: column;
        gap: 20px;
    }

    .manage-button {
        font-size: 14px;
    }


    .custom-file-input input[type="text"] {
        width: 100%;
        padding: 5px 30px;
        font-size: 13px;
    }

    /* edit contract page  */

    /* alert start */
    .alert-content {
        width: 90%;
    }

    .alert-content h2 {
        font-size: 40px;
    }

    .alert-content img {
        width: 240px;
        height: 240px;
    }

    .alert-content p {
        font-size: 18px;
        margin: 17px 0;
    }

    .alert-content span {
        font-size: 15px;
    }

    /* alert end */
}


@media screen and (min-width:768px) and (max-width: 1024px) {
    .app {
        height: auto;
        padding-bottom: 40px;
    }

    .container {
        width: 95%;
    }

    /* header start */
    .header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header .logo {
        width: 262px;
        height: 48px;
    }

    .header .connect-btn {
        margin-top: 20px;
    }

    /* header end */

    /* main page start */
    .main-page {
        flex-direction: column;
        height: auto;
    }

    .main-page_text {
        width: 100%;
        padding-left: 20px;
    }

    .main-page_text h2 {
        margin-top: 50px;
        font-size: 18px;
    }

    .main-page_text h1 {
        font-size: 40px;
        margin: 20px 0;
    }

    .main-page_text p {
        font-size: 17px;
    }

    .main-page_text button {
        font-size: 23px;
        padding: 12px;
        margin-top: 30px;
    }

    .main-page_img {
        width: 100%;
        height: 49%;
    }

    .main-page_img img {
        border-radius: 0 0 32px 32px;
    }

    /* main page end */

    /* connected page start */
    .connected-page {
        height: auto;
        background: linear-gradient(180deg, #FFCFEC 0%, #C1BDEC 100%);
        box-shadow: 0px 4px 50px 0px #0000000F;
        padding: 40px;
    }

    .connected-page h2 {
        font-size: 25px;
    }

    .connected-page .connected-page_release {
        width: 100%;
        gap: 15px;
        flex-wrap: wrap;
    }

    .connected-page_release p {
        width: 100%;
        font-weight: 400;
        font-size: 19px;
        color: #FFFFFF;
    }

    .connected-page_content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .connected-page_content .content-data {
        width: 100%;
    }

    .connected-page_content .content-data .content-data_card {
        width: 100%;
    }

    .connected-page_content .content-data .content-data_card h4 {
        font-size: 20px;
    }

    .connected-page_content .content-data .content-data_card .data-counts {
        font-size: 22px;
    }

    .claim-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .claim-btn img {
        width: 130px;
        height: 130px;
    }

    .connect-left_bg {
        display: none;
    }

    .connect-right_bg {
        display: none;
    }

    /* connected page end */

    /*  manage page */

    .manage {
        background: none;
        box-shadow: none;
        border-radius: 0;
    }

    .manage-head {
        background: none;
        box-shadow: none;
        padding: 10px 0;
        border-radius: 0;
        justify-content: center;
    }

    .manage-add {
        background-color: #ECDCE9;
    }

    .manage-heading {
        display: none;
    }

    .manage-contract_address-title,
    .manage-contract_id-title,
    .manage-contract_startDate-title,
    .manage-contract_endDate-title {
        display: inline !important;
        color: #797979;
        font-size: 14px !important;
    }

    .manage-body {
        width: 100%;
        padding: 20px 0;
    }

    .manage-body_head {
        display: none;
    }

    .manage-body_body-component {
        margin: 20px 0;
        border-radius: 32px;
        background: #FFFFFF;
        padding: 40px 24px;
        border-bottom: none;
    }

    .manage-body_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    .manage-body_content .manage-contract_address {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        gap: 15px;
    }

    .manage-body_content .manage-contract_address span:last-child {
        text-align: start;
    }

    .manage-body_content li {
        margin: 15px 0;
        width: 100%;
        display: flex;
    }

    .manage-body_content li span:nth-child(2) {
        display: inline-block;
        text-align: end;
    }

    .manage-body_content li span {
        font-size: 13px;
        display: inline-block;
        width: 100%;
        font-weight: 700;
    }

    .manage-body_content .manage-actions .manage-edit_btn {
        position: absolute;
        right: 0;
        top: 15px;
    }

    /*  manage page end */

    /* manage edit page start */

    .manage-contract {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .manage-sidebar {
        width: 100%;
    }

    .manage-sidebar ul {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
    }

    .manage-sidebar ul li {
        display: flex;
        justify-content: center;
        gap: 9px;
        border-radius: 56px;
        padding: 8px 0px;
        width: 152px;
    }

    .manage-sidebar ul li img {
        display: none;
    }

    .manage-sidebar ul li span {
        font-size: 18px;
    }

    .manage-contract-content {
        width: 100%;
    }

    .manage-contract_component {
        width: 100%;
        padding: 40px 24px;
        border-radius: 32px;
    }


    .add-wallet_tabs {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ECDCE9;
        border-radius: 10px;
        padding: 6px 18px;
    }

    .add-wallet_tabs .add-tab {
        width: 49%;
    }

    .custom-file-input {
        flex-direction: column;
        gap: 20px;
    }

    .manage-button {
        font-size: 14px;
    }


    .custom-file-input input[type="text"] {
        width: 100%;
        padding: 5px 30px;
        font-size: 13px;
    }

    /* manage edit page end */

    /* alert start */
    .alert-content {
        width: 90%;
    }

    .alert-content h2 {
        font-size: 45px;
    }

    .alert-content img {
        width: 240px;
        height: 240px;
    }

    .alert-content p {
        font-size: 23px;
        margin: 17px 0;
    }

    .alert-content span {
        font-size: 20px;
    }

    /* alert end */
}